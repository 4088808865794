


































































































































































































































































































































































































































































































































































































































































































































































.ts_color_1 {
  color: red;
}
.searchbox {
  margin-bottom: 10px;
}
.remindContent {
  /deep/ .el-textarea__inner {
    height: 100px;
  }
}
